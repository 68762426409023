/* // Smallest device */
@media (min-width: 100px) and (max-width: 575px) {
  .mobile {
    display: inline;
  }
  .desktop {
    display: none;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .mobile {
    display: inline;
  }
  .desktop {
    display: none;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .mobile {
    display: inline;
  }
  .desktop {
    display: none;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: inline;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: inline;
  }
}
