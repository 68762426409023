.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    z-index: 10;
  }
  .popup_inner {
    position: relative;
    width: 70%;
    height: 70vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .popup_inner button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .popup_inner video {
    width: 70%;
  }

  .popup button {
      border: none;
      color: #231651;
      background-color: #fff;
      font-size: 1.5rem;
      padding: 0.5rem;
      margin-right: 5px;
  }

  .popup button:hover {
      cursor: pointer;
  }