/* // Smallest device */
@media (min-width: 100px) and (max-width: 575px) {
  .list-inline {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .list-inline li {
    padding: 3rem;
  }
  
  .contact-item {
    font-size: 1.5rem;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .list-inline {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .list-inline li {
    padding: 3rem;
  }
  
  .contact-item {
    font-size: 1.5rem;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .list-inline {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .list-inline li {
    padding: 3rem;
  }
  
  .contact-item {
    font-size: 1.5rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .list-inline {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .list-inline li {
    padding: 5rem;
  }
  
  .contact-item {
    font-size: 1.75rem;
    font-family: 'Open Sans', sans-serif;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .list-inline {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .list-inline li {
    padding: 5rem;
  }
  
  .contact-item {
    font-size: 2rem;
    font-family: 'Cutive', serif;
  }
}
  /* Pulse */
  @-webkit-keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  @keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  .hvr-pulse {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }
  .hvr-pulse:hover, .hvr-pulse:focus, .hvr-pulse:active {
    -webkit-animation-name: hvr-pulse;
    animation-name: hvr-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  
  /* Float */
  .hvr-float {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-float:hover, .hvr-float:focus, .hvr-float:active {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

