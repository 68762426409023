body {
  background: #fff;
  color: #4DCCBD;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

p {
  line-height: 150%;
}

ul {
  list-style: none;
  padding-left: 0;
}