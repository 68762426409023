.Experience {
    margin-bottom: 5rem;
}
.icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
  }
  
  .icon-container {
    flex: display;
    flex-direction: column;
  }

  .icon {
    font-size: 2rem;
    padding: 0.5rem;
  }

  a.doc-link:hover {
    color: #4DCCBD;
  }  

  /* Float */
.hvr-float {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-float:hover, .hvr-float:focus, .hvr-float:active {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  /* // Smallest device */
@media (min-width: 100px) and (max-width: 575px) {
  .icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .icon-container {
    flex: display;
    flex-direction: column;
  }

  .icon {
    font-size: 2rem;
    padding: 0.5rem;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .icon-container {
    flex: display;
    flex-direction: column;
  }

  .icon {
    font-size: 2rem;
    padding: 0.5rem;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  .icon-container {
    flex: display;
    flex-direction: column;
  }

  .icon {
    font-size: 1.5rem;
    padding: 0.3rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  
  .icon-container {
    flex: display;
    flex-direction: column;
  }

  .icon {
    font-size: 2rem;
    padding: 0.5rem;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  
  .icon-container {
    flex: display;
    flex-direction: column;
  }

  .icon {
    font-size: 2rem;
    padding: 0.5rem;
  }
}
