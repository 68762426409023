  .NavbarDesktop {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: 'Open Sans', sans-serif;
    font-size: 2rem;
    margin: 0;
  }
  
  .NavbarDesktop nav-item {
    position: relative;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.5s ease;
  }

  .NavbarDesktop a.Navbar-active {
    color: #857C8D;
  }
  
  .NavbarDesktop a {
    line-height: 125px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
  }
  
  .custom-underline {
    position: relative;
    display: inline-block;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }
  
  .custom-underline,
  .custom-underline:hover,
  .custom-underline:focus,
  .custom-underline:active {
    color: #4DCCBD;
    text-decoration: none;
  }
  
  .custom-underline::after {
    content: "";
    position: absolute;
  }
  
  .nav-item .custom-underline::after {
    top: 65%;
    height: 2px !important;
    width: 40%;
    left: 30%;
    background-color: #4DCCBD;
    transition: 0.4s ease-out all .1s;
  }
  
  .nav-item:hover .custom-underline::after {
    width: 90%;
    left: 5%;
    transition: 0.5s ease all;
  }