.header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    padding-top: 5rem;
    font-family: 'Cutive', serif;
    margin-bottom: 10rem;
  }
  
  .header-item {
    margin: 1rem;
  }
  
  .highlight span:hover {
    color: #D6FFF6;
  }

  .main-header {
    text-align: center;
    font-family: 'Cutive', serif;
    font-size: 5rem;
  }

  .red-text {
    color: #1D201F;
  }

  /* Skew Backward */
.hvr-skew-backward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.hvr-skew-backward:hover, .hvr-skew-backward:focus, .hvr-skew-backward:active {
  -webkit-transform: skew(10deg);
  transform: skew(10deg);
}

/* Skew Forward */
.hvr-skew-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.hvr-skew-forward:hover, .hvr-skew-forward:focus, .hvr-skew-forward:active {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
}

/* Smallest device */
@media (min-width: 100px) and (max-width: 575px) {
  .header {
    flex-direction: column;
  }

  .main-header {
    font-size: 3rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .header {
    flex-direction: column;
  }
  
  .main-header {
    font-size: 4rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .header {
    flex-direction: column;
  }

  .main-header {
    font-size: 5rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .header {
    flex-direction: row;
  }

  .main-header {
    font-size: 5rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .header {
    flex-direction: row;
  }

  .main-header {
    font-size: 5rem;
  }
}