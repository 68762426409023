.Card {
  margin: 2rem;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  background-color: #F6F6F6;
}

.Card .pointer {
  cursor: pointer;
}

.card-category {
    background-size: cover;
    background-position: center;
    height: 15rem;
    width: 21rem;
    padding: 10px;
  }

  .card-category-bottom {
    height: 6rem;
    position: relative;
  }

  .card-category-bottom .links {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .card-category-bottom .links a:hover {
    color: #231651;
  }
  
  .card-category p {
    visibility: hidden;
  }

  .card-category .icon {
    visibility: hidden;
  }
  
  .card-category:hover p {
    visibility: visible;
    font-size: 0.9rem;
    text-align: center;
    color: #D6FFF6;
    padding: 10px;
  }

  .card-category:hover .icon {
    visibility: visible;
    font-size: 2rem;
    text-align: center;
    color: #D6FFF6;
    padding: 10px;
  }
  
  /* Card grow css*/
  .hvr-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  .hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  /* Sweep To Bottom */
.hvr-sweep-to-bottom {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-sweep-to-bottom:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #231651;
    opacity: 0.8;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-sweep-to-bottom:hover, .hvr-sweep-to-bottom:focus, .hvr-sweep-to-bottom:active {
    color: white;
  }
  .hvr-sweep-to-bottom:hover:before, .hvr-sweep-to-bottom:focus:before, .hvr-sweep-to-bottom:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
