.BrainhubCarousel__arrows {
    background-color: #4DCCBD;
}

.BrainhubCarousel__arrows:hover {
    background-color: #4DCCBD;
    opacity: .8;
}

.BrainhubCarousel__arrows:hover:enabled {
    background-color: #4DCCBD;
    opacity: .8;
}

.quote {
    padding: 0 3rem;
}